
























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ProgressLoader extends Vue {
    @Prop({ required: false, type: String, default: '' })
    public msg!: string;
}
