import Vue from 'vue';
import { AxiosPromise } from 'axios';
import ObjectUtil from '@/utils/ObjectUtil';
import CommonRules from '@/validation/CommonRules';
import { Component, Prop } from 'vue-property-decorator';
import ProgressLoader from '@/components/common/ProgressLoader.vue';

@Component({
    components: {
        ProgressLoader
    }
})
export default class BaseCrudDialog extends Vue {
    @Prop({ required: false, type: Number })
    public id!: number;
    public dialog: boolean = false;
    @Prop({ required: false, type: Function })
    public create!: (entity: FormData|any) => AxiosPromise;
    @Prop({ required: false, type: Function })
    public defaultEntity!: () => AxiosPromise;
    @Prop({ required: false, type: Function })
    public edit!: (id: number) => AxiosPromise;
    @Prop({ required: false, type: Function })
    public update!: (id: number, entity: FormData|any) => AxiosPromise;
    @Prop({ required: false, type: Function })
    public destroy!: (id: number) => AxiosPromise;
    public entity = {};
    public loading: boolean = false;
    public progressMsg: string = '';
    public title: string = 'Create';
    public entityType: string = '';
    public rules = CommonRules;
    public valid: boolean = false;

    public async getDefaultEntity(): Promise<void> {
        this.dialog = true;
        this.progressMsg = `Getting default ${this.entityType}...`;
        this.loading = true;

        try {
            const response = await this.defaultEntity();
            this.entity = response.data.default;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    }

    public async getEntityById(func: () => void): Promise<void> {
        this.dialog = true;
        this.title = 'Edit';
        this.progressMsg = `Getting ${this.entityType}...`;
        this.loading = true;

        try {
            const response = await this.edit(this.id);
            this.entity = response.data.entity;

            if (ObjectUtil.isFunction(func)) {
                func();
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    }

    public submit(): void {
        if (this.id) {
            this.update(this.id, this.entity).then(() => {
                this.emitRefresh();

                this.close();
            });

            return;
        }

        this.create(this.entity).then(() => {
            this.emitRefresh();

            this.close();
        });
    }

    public close(): void {
        this.dialog = false;
    }

    public remove(): void {
        this.destroy(this.id).then(() => {
            this.emitRefresh();

            this.close();
        });
    }

    public emitRefresh(): void {
        this.$parent.$emit('refresh');
    }
}
