











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class EditButton extends Vue {
    @Prop({ required: false, type: String, default: () => 'mdi-pencil' })
    public icon!: string;

    public getEntityById(): void {
        this.$emit('getEntityById');
    }
}
